const questionsData = [

    {
        id: 'age',
        type: 'single',
        layout: 'two-columns',
        question: 'AI-driven income growth plan according to your age',
        // description: 'This information will help us assess your personal procrastination score.',
        options: ['18-24', '25-34', '35-44', '45+'],
        optionImages: [
            '../assets/images/gender/male1.png',
            '../assets/images/gender/male2.png',
            '../assets/images/gender/male3.png',
            '../assets/images/gender/male4.png',
        ],
        optionImagesSize: '96px',
        transition: '',
        // image: '../assets/images/question-illustrations/q1-alt.png',
    },
    {
        type: 'single',
        layout: 'two-columns',
        question: `What is your goal?`,
        description: 'Please select your first priority.',
        options: ['Grow wealth', 'Be my own boss', 'Travel more', 'Other'],
        optionImages: [
            '../../assets/images/question-answers/money-bag.png',
            '../../assets/images/question-answers/briefcase.png',
            '../../assets/images/question-answers/globe.png',
            '../../assets/images/question-answers/question-mark.png',
        ],
        optionImagesSize: '48px',
    },
    {
        type: 'single',
        // image: '../../assets/images/question-illustrations/q13.png',
        question: `Do you often worry about job security and income stability?`,
        options: [`Yes`, `No`]
    },
    {
        type: 'single',
        // image: '../../assets/images/question-illustrations/q13.png',
        question: 'Do you often find yourself with no free time to pursue hobbies or passion projects?',
        options: [`Yes`, `No`]
    },
    {
        type: 'single',
        // image: '../../assets/images/question-illustrations/q13.png',
        question: 'Are you currently overwhelmed with the amount of work you have?',
        options: [`Yes`, `No`]
    },
    {
        type: 'single',
        // image: '../../assets/images/question-illustrations/q13.png',
        question: `Do you feel like your current job doesn't fully utilize your skills or potential?`,
        options: [`Yes`, `No`]
    },
    {
        type: 'single',
        // image: '../../assets/images/question-illustrations/q13.png',
        question: `Have concerns about AI replacing your job or skills crossed your mind?`,
        options: [`Yes`, `No`]
    },
    {
        type: 'single',
        image: '../../assets/images/question-illustrations/q13.png',
        question: 'What is your current work schedule?',
        description: 'Choose your main income source.',
        options: [`9-to-5 job`, `Night shifts`, 'Freelance', `Other`]
    },
    {
        id: 'last-minute',
        type: 'single',
        question: 'When did you last feel financially secure?',
        layout: 'two-columns',
        description: '',
        options: [
            'In the last 6 months',
            '6-12 months ago',
            '1-2 years ago',
            'Over 2 years ago'
        ],        
        optionImages: [
            '../../assets/images/question-answers/smiling.png',
            '../../assets/images/question-answers/expressionless.png',
            '../../assets/images/question-answers/confused.png',
            '../../assets/images/question-answers/pensive.png',
        ],
        optionImagesSize: '48px',
    },
    {
        type: 'single',
        question: 'Are you currently using any AI tools in your work process?',
        options: ['Yes', 'No'],
        // optionImages: [
        //     '../../assets/images/question-answers/income1.svg',
        //     '../../assets/images/question-answers/income3.svg',
        //     // '../../assets/images/question-answers/income2.svg',
        // ],
        optionImagesSize: '48px',
    },

    {
        type: 'single',
        question: `Annual income level you want to have`,
        description: '',
        options: ['$30,000 - $50,000', '$50,000 - $100,000', 'More than $100,000'],
        image: '../../assets/images/question-illustrations/q7.png',
    },
    // {
    //     type: 'single',
    //     question: 'Is the ability to work remotely important to you?',
    //     options: ['Yes', 'Maybe', 'No'],
    //     // image: '../assets/images/question-illustrations/q5.png',
    // },
    {
        type: 'single',
        question: 'Do you struggle to balance work and family time?',
        layout: 'split',
        description: '',
        options: ['Absolutely', 'Somewhat', 'I have work-life balance', `Prefer not to say`],
        image: '../../assets/images/question-illustrations/q5.png',
    },
    // {
    //     type: 'single',
    //     question: 'What kind of work situation do you aspire to have?',
    //     // description: 'Choose as many as you like',
    //     options: [`Remote balance`, `Stable routine`, `I'm exploring options`],
    //     // image: '../assets/images/question-illustrations/q7.png',
    // },
    {
        type: 'single',
        layout: 'two-columns',
        question: 'Do you wish you could travel while working?',
        description: '',
        options: [`It's a dream`, `I'm traveling`, `Sometimes`, `Not a priority`],
        image: '../../assets/images/question-illustrations/q6.png',
    },
    {
        type: "single",
        layout: 'two-columns',
        question: "How experienced are you with online businesses?",
        // description: "Choose the option that best describes your familiarity with digital businesses to tailor the content to your level.",
        options: ["No Experience - I'm not familiar at all", "Beginner - I'm just starting to explore", "Intermediate - I've set up an online business", "Experienced - I successfully manage online businesses"],
        "optionImages": [
            "../../assets/images/question-answers/seedling.png",
            "../../assets/images/question-answers/magnifying-glass.png",
            "../../assets/images/question-answers/books.png",
            "../../assets/images/question-answers/light-bulb.png"
        ],
        optionImagesSize: "48px"
    },
    {
        type: 'single',
        layout: 'two-columns',
        question: 'How good are you at copywriting?',
        description: 'According to Upwork, copywriters make around $68,000 anually.',
        options: ["No Experience - I'm not familiar at all", `Beginner - I need more information`, 'Intermediate - I understand the basics and can write a bit', `Experienced - I'm skilled and can write high-quality copy`],
        // image: '../assets/images/question-illustrations/q6.png',
        optionImages: [
            "../../assets/images/question-answers/seedling.png",
            "../../assets/images/question-answers/magnifying-glass.png",
            '../../assets/images/question-answers/books.png',
            '../../assets/images/question-answers/light-bulb.png',
        ],
        optionImagesSize: '48px',
    },
    {
        type: 'single',
        question: 'How good are you at digital marketing?',
        description: 'According to Upwork, digital marketers make around $100,000 anually.',
        layout: 'two-columns',
        options: ["No Experience - I'm not familiar at all", 'Beginner - I need more information', `Intermediate - I'm comfortable with social media and email marketing basics`, `Experienced - I'm skilled and can execute complex digital marketing strategies`],
        optionImages: [
            "../../assets/images/question-answers/seedling.png",
            "../../assets/images/question-answers/magnifying-glass.png",
            '../../assets/images/question-answers/books.png',
            '../../assets/images/question-answers/light-bulb.png',
        ],
        optionImagesSize: '48px',
    },

    {
        type: 'multi',
        question: `What do you want AI to help you with?`,
        options: [
            '🤖 Automating routine tasks',
            '💡 Finding new earning opportunities',
            '✍️ Creating digital content',
            '🌐 Managing social media',
            '🎨 Designing custom graphics and art',
            '📈 Offering financial planning and advice',
            '📣 Promoting your business'    
        ],
        // image: '../assets/images/question-illustrations/q10.png',
    },
    {
        type: 'multi',
        question: 'Choose AI platforms you have heard of',
        layout: 'two-columns',
        options: [
            'ChatGPT', 'Claude', 'Gemini', 'MidJourney', 'Stability AI', 'Character AI', 'Civitai', 'Perplexity', `I haven't heard any of them`
        ],

        optionImages: [
            '../../assets/images/question-answers/ai-platforms/chatgpt.png',
            '../../assets/images/question-answers/ai-platforms/claude.png',
            '../../assets/images/question-answers/ai-platforms/gemini.png',
            '../../assets/images/question-answers/ai-platforms/midjourney.png',
            '../../assets/images/question-answers/ai-platforms/stabilityai.png',
            '../../assets/images/question-answers/ai-platforms/characterai.png',
            '../../assets/images/question-answers/ai-platforms/civitai.png',
            '../../assets/images/question-answers/ai-platforms/perplexity.png',
        ],
        optionImagesSize: '32px',
    },
    // readiness
    {
        type: 'multi',
        question: `Choose jobs you might be interested in`,
        description: 'Choose as many as you like',
        options: ['Digital Marketing', 'Social Media Marketing', 'Affiliate Marketing', 'Dropshipping', 'Content Writing', 'Translation', 'No-code apps', 'Graphic Design', 'Blogging', 'None of the above'],
        optionImages: [
            '../../assets/images/question-answers/laptop.png',
            '../../assets/images/question-answers/phone.png',
            '../../assets/images/question-answers/briefcase.png',
            '../../assets/images/question-answers/package.png',
            '../../assets/images/question-answers/writing-hand.png',
            '../../assets/images/question-answers/fountain-pen.png',
            '../../assets/images/question-answers/globe-with-meridians.png',
            '../../assets/images/question-answers/artist-palette.png',
            '../../assets/images/question-answers/memo.png',
            // '../../assets/images/question-answers/paintbrush.png',
            // '../../assets/images/question-answers/magnifying-glass.png'
        ],
        optionImagesSize: '32px',
        // image: '../assets/images/question-illustrations/q12.png',
    },
    {
        id: 'time',
        type: 'single',
        question: 'How committed are you to learning AI to enhance your income potential?',
        description: '',
        options: [`Interested - I'm ready to explore how AI can begin to improve my income`,`Committed - I'm ready to actively learn and apply AI tools to enhance my earnings`, `All-In - I'm ready to fully master AI for substantial financial growth and career satisfaction`],
        optionImages: [
            '../../assets/images/question-answers/seedling.png',
            '../../assets/images/question-answers/books.png',
            '../../assets/images/question-answers/glowing-star.png',
        ],
        optionImagesSize: '48px',

    },
    {
        type: 'single',
        question: 'Is there something special you want to achieve?',
        options: ['Buy a house', 'Vacation', 'Buy a new car', 'Worry-free retirement', 'Other'],
        optionImages: [
            '../../assets/images/question-answers/house.png',
            // '../../assets/images/question-answers/wedding.png',
            '../../assets/images/question-answers/vacation.png',
            '../../assets/images/question-answers/car.png',
            '../../assets/images/question-answers/couch.png',
            '../../assets/images/question-answers/question-mark.png',
        ],
        optionImagesSize: '48px',
    },
    {
        type: 'single',
        question: 'How much time are you ready to spend to achieve your goal?',
        options: ['5 min/day', '10 min/day', '15 min/day', '20 min/day'],
    }
];

export default questionsData;
