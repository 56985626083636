import React from 'react';
import './LandingDisclaimer.css';

const LandingDisclaimer = () => {
    const currentYear = new Date().getFullYear();

  return (
    <div className="landing_disclaimer">
        <p className='landing_copyright'>{currentYear} © All rights reserved.</p>
        <p>DISCLAIMER: Results may vary from person to person. By continuing, you agree to with our Terms of Service, Privacy Policy, Money-Back Policy, Subscription terms, Cookie policy. Careeristo's content is for educational purposes only and is not professional career or financial advice. Consult qualified professionals for career and financial decisions.</p>
    </div>
  );
};

export default LandingDisclaimer;
