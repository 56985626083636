import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from "@sentry/react";
import TagManager from 'react-gtm-module';
import { BrowserRouter as Router } from 'react-router-dom';

import './index.css';
import App from './App';

TagManager.initialize({
  gtmId: process.env.REACT_APP_GTM_ID,
})

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  tracesSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>
);
