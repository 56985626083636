import Cookies from 'js-cookie';


export const setCookie = (key, value) => {
  Cookies.set(key, value, { path: '/', expires: 30 });
};

export const getCookie = (key) => {
  return Cookies.get(key) || '';
};

export const SetCookieIfNotSet = (key, value) => {
  if (value !== null && getCookie(key) === '') {
    setCookie(key, value);
  }
};
