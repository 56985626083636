import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <a href="/terms" className="footer__link">Terms and Conditions</a>
      <a href="/privacy-policy" className="footer__link">Privacy Policy</a>
      <a href="/contact" className="footer__link">Contact</a>
    </footer>
  );
};

export default Footer;
