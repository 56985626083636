const questionsData = [

    {
        id: 'age',
        type: 'single',
        layout: 'two-columns',
        question: 'How old are you?',
        // description: 'This information will help us assess your personal procrastination score.',
        options: ['18-24', '25-34', '35-44', '45+'],
        optionImages: [
            '../assets/images/gender/male1.png',
            '../assets/images/gender/male2.png',
            '../assets/images/gender/male3.png',
            '../assets/images/gender/male4.png',
        ],
        optionImagesSize: '96px',
        transition: '',
        // image: '../assets/images/question-illustrations/q1-alt.png',
    },
    {
        type: 'single',
        layout: 'two-columns',
        question: `What is your ultimate goal?`,
        description: 'Select your top priority to achieve.',
        options: ['Grow wealth', 'Be my own boss', 'Travel more', 'Achieve a personal goal'],
        optionImages: [
            '../../assets/images/question-answers/money-bag.png',
            '../../assets/images/question-answers/briefcase.png',
            '../../assets/images/question-answers/globe.png',
            '../../assets/images/question-answers/target.png',
        ],
        optionImagesSize: '48px',
    },
    {
        type: 'single',
        // layout: 'split',
        // image: '../../assets/images/question-illustrations/q5.png',
        question: `What's your current work situation?`,
        description: 'Please select your main source of income.',
        options: ['Full-time employee', 'Part-time employee', 'Self-employed', 'Unemployed', 'Student'],
        optionImages: [
            '../../assets/images/question-answers/man-office-worker.png',
            '../../assets/images/question-answers/hourglass-not-done.png',
            '../../assets/images/question-answers/house.png',
            '../../assets/images/question-answers/prohibited.png',
            '../../assets/images/question-answers/graduation-cap.png',
        ],
        optionImagesSize: '48px',
    },
    {
        type: 'single',
        // image: '../../assets/images/question-illustrations/q13.png',
        question: 'Do you find yourself doing a lot of manual tasks at work?',
        options: [`Yes`, `No`, `I don't know`]
    },    {
        type: 'single',
        // image: '../../assets/images/question-illustrations/q13.png',
        question: `Have you used any AI tools for work in the last 6 months?`,
        options: [`Yes`, `No`, `I don't know`]
    },
    {
        type: 'single',
        question: 'What is the biggest challenge you face in managing your work tasks?',
        layout: 'two-columns',
        options: ['Lack of time', 'Too many distractions', 'Inefficient processes', 'Lack of tools'],
        optionImages: [
            '../../assets/images/question-answers/hourglass-not-done.png',
            '../../assets/images/question-answers/phone.png',
            '../../assets/images/question-answers/gear.png',
            '../../assets/images/question-answers/tools.png',
        ],
        optionImagesSize: '32px',
    },
    {
        type: 'multi',
        // image: '../../assets/images/question-illustrations/q13.png',
        question: 'What tasks take up most of your time at work?',
        layout: 'two-columns',
        description: 'Select all that apply',
        options: [
            `📧 Emails`,
            `📅 Scheduling meetings`,
            `🖥️ Data entry`,
            `🔍 Research`,
            `📊 Reporting`,
            `📱 Managing social media`,
            `🤝 Customer service`,
            `📦 Inventory management`,
            `🗂️ Project management`,
            `📝 Administrative tasks`,
            `📞 Phone calls`,
            'Other'
        ]
    },
    {
        // id: 'last-minute',
        type: 'single',
        question: 'How often do you feel overwhelmed by your workload?',
        layout: 'two-columns',
        description: '',
        options: [
            `Daily`, `Weekly`, 'Monthly', `Rarely`
        ],        
        optionImages: [
            '../../assets/images/question-answers/pensive.png',
            '../../assets/images/question-answers/confused.png',
            '../../assets/images/question-answers/expressionless.png',
            '../../assets/images/question-answers/smiling.png',
        ],
        optionImagesSize: '48px',
    },
    {
        type: 'multi',
        // image: '../../assets/images/question-illustrations/q13.png',
        question: `What are your primary goals for using an AI assistant?`,
        layout: 'two-columns',
        description: 'Select all that apply',
        options: [`⏰ Save time`, `🚀 Increase productivity`, `😌 Reduce stress`, '🤑 Earn more',  '⚖️ Improve work-life balance', `✅ Improve accuracy`]
    },
    {
        type: 'single',
        question: 'Do you struggle to balance work and family time?',
        layout: 'split',
        description: '',
        options: ['Absolutely', 'Somewhat', 'I have work-life balance', `Prefer not to say`],
        image: '../../assets/images/question-illustrations/q5.png',
    },
    {
        type: 'single',
        image: '../../assets/images/question-illustrations/q2.png',
        question: 'How often do you work late or on weekends?',
        options: [            'Always',
            'Often',
            'Sometimes',
            'Rarely',
            'Never'
        ]
    },
    {
        type: 'single',
        image: '../../assets/images/question-illustrations/q13.png',
        question: 'Would you like a job where routine tasks are done for you?',
        options: ['Absolutely',
                'Yes, somewhat',
                'Maybe',
                'Not really']
    },
    {
        type: 'multi',
        question: 'Do you need help with any of the following issues?',
        // layout: 'two-columns',
        description: 'Select all that apply',
        options: [  'Staying focused on tasks',
                    'Avoiding procrastination',
                    'Managing time effectively',
                    'Handling work-related stress',
                    'Balancing work and personal life',
                    'Staying motivated',
                    'None of the above'
                ],
                optionImages: [
                    '../../assets/images/question-answers/brain.png',
                    '../../assets/images/question-answers/phone.png',
                    '../../assets/images/question-answers/alarm-clock.png',
                    '../../assets/images/question-answers/anxious.png',
                    '../../assets/images/question-answers/balance.png',
                    '../../assets/images/question-answers/bicep.png',
                ],
                optionImagesSize: '32px',        
    },
    {
        type: 'single',
        question: 'How many hours per week do you want to save using our AI assistant?',
        layout: 'split',
        description: '',
        options: ['1-5 hours', '6-10 hours', '11-15 hours', `16+ hours`],
        image: '../../assets/images/question-illustrations/q7.png',
    },
    {
        type: 'single',
        question: 'What big dream are you working towards?',
        options: ['Buy a house', 'Extended Vacation', 'Get a new car', 'Enjoy worry-free retirement', 'Other'],
        optionImages: [
            '../../assets/images/question-answers/house.png',
            // '../../assets/images/question-answers/wedding.png',
            '../../assets/images/question-answers/vacation.png',
            '../../assets/images/question-answers/car.png',
            '../../assets/images/question-answers/couch.png',
            '../../assets/images/question-answers/question-mark.png',
        ],
        optionImagesSize: '48px',
    },
    {
        type: 'single',
        question: 'How much time are you ready to spend to achieve your goal?',
        options: ['⏱️ 5 min/day', '⏱️ 10 min/day', '⏱️ 15 min/day', '⏱️ 20 min/day'],
    }
];

export default questionsData;
