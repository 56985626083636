const questionsData = [

    {
        id: 'age',
        type: 'single',
        layout: 'two-columns',
        question: 'Career freedom plan according to your age',
        // description: 'This information will help us assess your personal procrastination score.',
        options: ['18-24', '25-34', '35-44', '45+'],
        optionImages: [
            '../assets/images/gender/male1.png',
            '../assets/images/gender/male2.png',
            '../assets/images/gender/male3.png',
            '../assets/images/gender/male4.png',
        ],
        optionImagesSize: '96px',
        transition: '',
        // image: '../assets/images/question-illustrations/q1-alt.png',
    },
    {
        type: 'single',
        layout: 'two-columns',
        question: `What is your goal?`,
        description: 'Please select your first priority.',
        options: ['Grow wealth', 'Be my own boss', 'Travel more', 'Other'],
        optionImages: [
            '../../assets/images/question-answers/money-bag.png',
            '../../assets/images/question-answers/briefcase.png',
            '../../assets/images/question-answers/globe.png',
            '../../assets/images/question-answers/question-mark.png',
        ],
        optionImagesSize: '48px',
    },
    {
        type: 'single',
        image: '../../assets/images/question-illustrations/q13.png',
        question: 'What is your current income source?',
        description: 'Choose your main income.',
        options: [`9-to-5 job`, 'Freelance', `Other`]
    },
    {
        type: 'single',
        question: 'Choose your current income level',
        options: ['Low', 'Medium', 'High'],
        optionImages: [
            '../../assets/images/question-answers/income1.svg',
            '../../assets/images/question-answers/income3.svg',
            '../../assets/images/question-answers/income2.svg',
        ],
        optionImagesSize: '48px',
    },
    {
        id: 'last-minute',
        type: 'single',
        question: 'How would you describe your financial situation?',
        layout: 'two-columns',
        description: '',
        options: [
            `I'm struggling to meet my financial goals`,
            `I would like to have more stability`,
            `I'm not sure`,
            `I'm comfortable`],
        optionImages: [
            '../../assets/images/question-answers/pensive.png',
            '../../assets/images/question-answers/confused.png',
            '../../assets/images/question-answers/expressionless.png',
            '../../assets/images/question-answers/smiling.png',
        ],
        optionImagesSize: '48px',
    },

    {
        type: 'single',
        question: `Annual income level you want to have`,
        description: '',
        options: ['$30,000 - $50,000', '$50,000 - $100,000', 'More than $100,000'],
        image: '../../assets/images/question-illustrations/q7.png',
    },
    {
        type: 'single',
        question: 'Do you value the flexibility of remote work in your career?',
        options: ['Yes', 'Maybe', 'No'],
        // image: '../assets/images/question-illustrations/q5.png',
    },
    {
        type: 'single',
        question: 'Do you struggle to balance work and family time?',
        layout: 'split',
        description: '',
        options: ['Absolutely', 'Somewhat', 'I have work-life balance', `Prefer not to say`],
        image: '../../assets/images/question-illustrations/q5.png',
    },
    {
        type: 'single',
        question: 'What kind of work situation do you aspire to have?',
        // description: 'Choose as many as you like',
        options: [`Remote balance`, `Stable routine`, `I'm exploring options`],
        // image: '../assets/images/question-illustrations/q7.png',
    },
    {
        type: 'single',
        layout: 'two-columns',
        question: 'Do you wish you could travel while working?',
        description: '',
        options: [`It's a dream`, `I'm traveling`, `Sometimes`, `Not a priority`],
        image: '../../assets/images/question-illustrations/q6.png',
    },
    {
        type: 'single',
        question: 'Rate your knowledge in content writing',
        description: 'According to Upwork, freelance content writers make around $42,000 annually.',
        options: ['I need more information', 'I know a thing or two', 'I know everything'],
        // image: '../assets/images/question-illustrations/q6.png',
        optionImages: [
            '../../assets/images/question-answers/thinking-face.png',
            '../../assets/images/question-answers/books.png',
            '../../assets/images/question-answers/light-bulb.png',
        ],
        optionImagesSize: '48px',

    },
    {
        type: 'single',
        question: `Did you know that AI tools can make creation of content even easier?`,
        options: ['No, this is news to me', `I'm curious`, `Yes, I've heard of it`],
        // image: '../assets/images/question-illustrations/q10.png',
    },
    // testimonial
    {
        type: 'single',
        question: 'Rate your knowledge in digital marketing',
        description: 'According to Upwork, digital marketers make around $100,000 anually.',
        options: ['I need more information', 'I know a thing or two', 'I know everything'],
        optionImages: [
            '../../assets/images/question-answers/thinking-face.png',
            '../../assets/images/question-answers/books.png',
            '../../assets/images/question-answers/light-bulb.png',
        ],
        optionImagesSize: '48px',
    },

    {
        type: 'single',
        question: 'Rate your ability to promote business',
        description: '70% of freelancers find their job online, and you can too.',
        options: ['I need more information', 'I know a thing or two', `I'm totally prepared`],
        optionImages: [
            '../../assets/images/question-answers/thinking-face.png',
            '../../assets/images/question-answers/books.png',
            '../../assets/images/question-answers/light-bulb.png',
        ],
        optionImagesSize: '48px',

    },

    {
        type: 'single',
        question: `Did you know that AI tools can help with promotion?`,
        options: ['Hard to believe', `I'm curious`, `Yes, I've heard of it`],
        // image: '../assets/images/question-illustrations/q13.png',
    },
    {
        type: 'multi',
        question: `Choose jobs you might be interested in`,
        description: 'Choose as many as you like',
        options: ['Digital Marketing', 'Social Media Marketing', 'Affiliate Marketing', 'Dropshipping', 'Content Writing', 'Copywriting', 'Translation', 'Graphic Design', 'Blogging', 'Illustration', 'No-code Apps', 'None of the above'],
        optionImages: [
            '../../assets/images/question-answers/laptop.png',
            '../../assets/images/question-answers/phone.png',
            '../../assets/images/question-answers/briefcase.png',
            '../../assets/images/question-answers/package.png',
            '../../assets/images/question-answers/writing-hand.png',
            '../../assets/images/question-answers/fountain-pen.png',
            '../../assets/images/question-answers/globe-with-meridians.png',
            '../../assets/images/question-answers/artist-palette.png',
            '../../assets/images/question-answers/memo.png',
            '../../assets/images/question-answers/paintbrush.png',
            '../../assets/images/question-answers/magnifying-glass.png'
        ],
        optionImagesSize: '32px',
        // image: '../assets/images/question-illustrations/q12.png',
    },
    {
        id: 'time',
        type: 'single',
        question: 'Rate your readiness to start freelancing',
        description: '',
        options: ['I need more information', `I'm somewhat ready`, `I'm totally prepared`],
        optionImages: [
            '../../assets/images/question-answers/thumbs-down.png',
            '../../assets/images/question-answers/person-shrugging.png',
            '../../assets/images/question-answers/thumbs-up.png',
        ],
        optionImagesSize: '48px',

    },
    {
        type: 'multi',
        question: 'Choose freelance platforms you have heard of',
        layout: 'two-columns',
        options: [
            'Upwork', 'Fiverr', 'Toptal', 'Jooble', 'Freelancer.com', 'Flexjobs', 'SimplyHired', 'Guru', 'LinkedIn', 'Behance', `I haven't heard any of them`
        ],

        optionImages: [
            '../../assets/images/question-answers/upwork.png',
            '../../assets/images/question-answers/fiverr.png',
            '../../assets/images/question-answers/toptal.png',
            '../../assets/images/question-answers/jooble.png',
            '../../assets/images/question-answers/freelancercom.png',
            '../../assets/images/question-answers/flexjobs.png',
            '../../assets/images/question-answers/simplyhired.png',
            '../../assets/images/question-answers/guru.png',
            '../../assets/images/question-answers/linkedin.png',
            '../../assets/images/question-answers/behance.png',
        ],
        optionImagesSize: '32px',
    },
    {
        type: 'single',
        question: 'Is there something special you want to achieve?',
        options: ['Buy a house', 'A perfect wedding', 'Vacation', 'Buy a new car', 'Worry-free retirement', 'Other'],
        optionImages: [
            '../../assets/images/question-answers/house.png',
            '../../assets/images/question-answers/wedding.png',
            '../../assets/images/question-answers/vacation.png',
            '../../assets/images/question-answers/car.png',
            '../../assets/images/question-answers/couch.png',
            '../../assets/images/question-answers/question-mark.png',
        ],
        optionImagesSize: '48px',
    },
    {
        type: 'single',
        question: 'How much time are you ready to spend to achieve your goal?',
        options: ['5 min/day', '10 min/day', '15 min/day', '20 min/day'],
    }
];

export default questionsData;
