import React, { useState, useEffect, Suspense, lazy } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { createUser, saveUserQuizAnswers } from '../../api/api';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import HeroSectionNew from './components/HeroSectionNew';
import LandingDisclaimer from './components/LandingDisclaimer';


// Import data and lazy-load pages specific to the freelance1 funnel
import questionsData from './questionsData';
const QuestionPage = lazy(() => import('./pages/QuestionPage'));
const PromisePage = lazy(() => import('./pages/PromisePage'));
const TransitionPage = lazy(() => import('./pages/TransitionPage'));
const TestimonialPage = lazy(() => import('./pages/TestimonialPage'));
const ReadinessPage = lazy(() => import('./pages/ReadinessPage'));
const ConfidentPage = lazy(() => import('./pages/ConfidentPage'));
const EmailCapturePage = lazy(() => import('./pages/EmailCapturePage'));
const FinalPage = lazy(() => import('./pages/FinalPage'));
const CalculatingResults = lazy(() => import('./pages/CalculatingResults'));
const CheckoutPage = lazy(() => import('./pages/CheckoutPage'));
const SpecialOffer = lazy(() => import('./pages/SpecialOffer'));
const UpgradePage = lazy(() => import('./pages/UpgradePage'));
const SuccessPage = lazy(() => import('./pages/SuccessPage'));


const TermsAndConditions = lazy(() => import('../../pages/TermsAndConditions'));
const ContactPage = lazy(() => import('../../pages/ContactPage'));
const PrivacyPolicy = lazy(() => import('../../pages/PrivacyPolicy'));

// const basePath = "../../freelance1";


// const QuestionPage = lazy(() => import('./funnels/freelance1/pages/QuestionPage'));
// const PromisePage = lazy(() => import('./funnels/freelance1/pages/PromisePage'));
// const TransitionPage = lazy(() => import('./funnels/freelance1/pages/TransitionPage'));
// const TestimonialPage = lazy(() => import('./funnels/freelance1/pages/TestimonialPage'));
// const ReadinessPage = lazy(() => import('./funnels/freelance1/pages/ReadinessPage'));
// const ConfidentPage = lazy(() => import('./funnels/freelance1/pages/ConfidentPage'));
// const FinalPage = lazy(() => import('./funnels/freelance1/pages/FinalPage'));
// const EmailCapturePage = lazy(() => import('./funnels/freelance1/pages/EmailCapturePage'));
// const CalculatingResults = lazy(() => import('./funnels/freelance1/pages/CalculatingResults'));
// const CheckoutPage = lazy(() => import('./funnels/freelance1/pages/CheckoutPage'));
// const TermsAndConditions = lazy(() => import('./pages/TermsAndConditions'));
// const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
// const SuccessPage = lazy(() => import('./funnels/freelance1/pages/SuccessPage'));
// const ContactPage = lazy(() => import('./pages/ContactPage'));
// const SpecialOffer = lazy(() => import('./funnels/freelance1/pages/SpecialOffer'));
// const UpgradePage = lazy(() => import('./funnels/freelance1/pages/UpgradePage'));

function FunnelFreelance({ basePath, funnelName, funnelVersion }) {
    const location = useLocation();
    const [answers, setAnswers] = useState([]);
    const [selectedGender, setSelectedGender] = useState(null); // Add this line
  
    const gender = localStorage.getItem("gender");
    if (gender && !selectedGender) {
      setSelectedGender(gender);
    }
  
    const handleAnswer = (answer, questionNumber, question) => {
      const newAnswers = [...answers];
      newAnswers[questionNumber - 1] = answer;
      setAnswers(newAnswers);
  
      const userId = localStorage.getItem("userId");
      if (userId) {
        if (typeof answer === 'string') {
          answer = [answer];
        }
        saveUserQuizAnswers(userId, question, answer, funnelName, funnelVersion);
      }
    };

    return (
        <Suspense fallback={<div>Loading...</div>}>
        <Routes>

        <Route path="/" element={<><Header /><div className='App__content'>
        <HeroSectionNew setSelectedGender={setSelectedGender} basePath={basePath} funnelName={funnelName} funnelVersion={funnelVersion} /> 
          </div>
          <LandingDisclaimer />
        <Footer /></>} />

        {questionsData.map((questionData, index) => (
            <Route
              key={`question-${index}`}
              path={`/question/${index + 1}`}
              element={
                <QuestionPage
                  key={`question-${index}`}
                  questionNumber={index + 1}
                  totalQuestions={questionsData.length}
                  handleAnswer={handleAnswer}
                  cachedImages={questionsData.image}
                  selectedGender={selectedGender} // Pass selectedGender as prop
                  basePath={basePath} 
                  {...questionData}
                />
              }
            />
          ))}
          <Route path="/promise" element={<PromisePage selectedGender={selectedGender} answers={answers} questionNumber={1} totalQuestions={questionsData.length} basePath={basePath} />} />
            <Route path="/transition" element={<TransitionPage answers={answers} questionNumber={15} totalQuestions={questionsData.length} basePath={basePath} />} />
            <Route path="/testimonial" element={<TestimonialPage answers={answers} questionNumber={13} totalQuestions={questionsData.length} basePath={basePath} />} />
            <Route path="/confident" element={<ConfidentPage answers={answers} questionNumber={20} totalQuestions={questionsData.length} basePath={basePath} />} />
            <Route path="/email" element={<EmailCapturePage questionNumber={questionsData.length} totalQuestions={questionsData.length} basePath={basePath} />} />
            <Route path="/final" element={<FinalPage questionNumber={questionsData.length} totalQuestions={questionsData.length} />} />
            <Route path="/readiness" element={<ReadinessPage answers={answers} basePath={basePath} />} />
            <Route path="/calculating-results" element={<CalculatingResults basePath={basePath} />} />
            <Route path="/checkout" element={<CheckoutPage answers={answers} selectedGender={selectedGender} basePath={basePath} />} />
            <Route path="/terms" element={<><Header /><div className='App__content'><TermsAndConditions /></div><Footer /></>} />
            <Route path="/privacy-policy" element={<><Header /><div className='App__content'><PrivacyPolicy /></div><Footer /></>} />
            <Route path="/success" element={<SuccessPage />} />
            <Route path="/contact" element={<><Header /><div className='App__content'><ContactPage /></div><Footer /></>} />
            <Route path="/special-offer" element={<SpecialOffer basePath={basePath} />} />
            <Route path="/upgrade" element={<UpgradePage basePath={basePath} />} />
        </Routes>
      </Suspense>    
    )
}
export default FunnelFreelance;
