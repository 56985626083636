import React from 'react';
import { useNavigate } from 'react-router-dom';
import TagManager from 'react-gtm-module';

import './HeroSectionNew.css';
import { saveUserQuizAnswers } from '../../../api/api';

const HeroSectionNew = ({ setSelectedGender, basePath, funnelName, funnelVersion }) => {
  const navigate = useNavigate();

  const trackSelectGender = (gender) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'dl_select_gender',
        experimentGroup: 'control',
        gender,
      },
    });
  };

  const handleClick = (gender) => {
    trackSelectGender(gender);
    setSelectedGender(gender); // Store the selected gender
    localStorage.setItem('gender', gender);

    const userId = localStorage.getItem("userId");
    if (userId) {
      saveUserQuizAnswers(userId, "Start by selecting your gender", [gender], funnelName, funnelVersion);
    }

    navigate(`${basePath}/question/1`);
  };

  return (
    <div className="hero-container">
      <h2 className="hero-title">Create Your Path to Freedom</h2>
      <p className="hero-subtitle">Start by selecting your gender</p>
      <div className="hero-quiz-invite">1-MINUTE QUIZ</div>
      <div className="hero-gender-selection">
        <div className="gender-card male-card" onClick={() => handleClick('male')}>
          <div className="gender-image-container">
            <img fetchpriority="high" src="../assets/images/gender/male1.png" alt="Male" />
          </div>
          <div className="ai-gender-button male-button">Income Growth For MEN</div>
        </div>
        <div className="gender-card female-card" onClick={() => handleClick('female')}>
          <div className="gender-image-container">
            <img fetchpriority="high" src="../assets/images/gender/female1.png" alt="Female" />
          </div>
          <div className="ai-gender-button female-button">Income Growth For WOMEN</div>
        </div>
      </div>
    </div>
  );

};


export default HeroSectionNew;
