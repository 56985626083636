import React from 'react';
import './Header.css';

const Header = () => {
  return (
    <header className="header">
      <img src="/assets/images/logo256.png" alt="Logo" className="header__logo" />
    </header>
  );
};

export default Header;
